<template>
  <b-modal id="edit-password-modal" hide-footer centered hide-header>
    <b-row class="pb-2">
      <b-col cols="8">
        <span>{{ $t("g.editPassword") }}</span>
      </b-col>
      <b-col cols="4" align="end" @click="onCancel">
        <img :src="require('@/assets/images/pages/personal/icons/Icon.png')" />
      </b-col>
    </b-row>
    <validation-observer ref="changePasswordForm">
      <b-form @submit.prevent="validation">
        <b-row align-h="end">
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required"
                name="current password"
                #default="{ errors }"
              >
                <b-form-input
                  type="password"
                  v-model="passwordData.current"
                  :placeholder="$t('g.currentPassword')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required|min:9|max:11"
                name="New password"
                vid="pass"
                #default="{ errors }"
              >
                <b-form-input
                  type="password"
                  v-model="passwordData.new_password"
                  :placeholder="$t('g.newPassword')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required|confirmed:pass"
                name="confirm password"
                #default="{ errors }"
              >
                <b-form-input
                  type="password"
                  v-model="passwordData.confirem_password"
                  :placeholder="$t('g.newPasswordAgian')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="auto">
            <b-button type="submit">
              {{ $t("g.save") }}
            </b-button>
            <b-button
              class="mr-1"
              @click="onCancel()"
              variant="outline-transparent"
            >
              {{ $t("g.cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <overlay-component :isLoading="overlayLoading" />
  </b-modal>
</template>

<script>
import { required, password, confirmed, max, min } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BRow,
  BButton,
  BOverlay,
  BCol,
  BForm,
  BModal,
  BProgress,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
export default {
  name: "overlayForm",
  components: {
    OverlayComponent,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormFile,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BModal,
  },
  data() {
    return {
      overlayLoading: false,
      passwordData: {
        current: null,
        new_password: null,
        confirem_password: null,
      },
    };
  },
  methods: {
    validation() {
      this.$refs.changePasswordForm
        .validate()
        .then((success) => success && this.onSubmit());
    },
    async onSubmit() {
      try {
        this.overlayLoading = true;
        const formData = new FormData();
        formData.append("old_password", this.passwordData.current);
        formData.append("new_password", this.passwordData.new_password);
        formData.append(
          "new_password_confirmation",
          this.passwordData.confirem_password
        );
        await this.$http.post(
          "/auth/guardian/edit-profile?_method=put",
          formData
        );
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("pass_changed_successfully")
        );
        this.onCancel();
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    onCancel() {
      this.clearForm();
      this.$bvModal.hide("edit-password-modal");
    },
    clearForm() {
      this.passwordData = {
        current: null,
        new_password: null,
        confirem_password: null,
      };
    },
  },
};
</script>
